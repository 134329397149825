import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class StatusPageService {
    constructor(private http: HttpClient) {}

    getStatus(appId: string): Observable<AppStatus> {
        return this.http.get<AppStatus>(`${environment.http.statusPageBaseUrl}apps/${appId}`);
    }
}

export interface AppStatus {
    id: string;
    page_id: string;
    group_id: string;
    created_at: string;
    updated_at: string;
    name: string;
    description: string;
    status: string;
    showcase: boolean;
}
